import type { QueryClient, QueryKey } from "@tanstack/react-query";
import type { SyncStatus } from "~generated";

export function optimisticallyUpdateSyncStatusToRunning({
  queryKey,
  queryClient,
}: {
  queryKey: QueryKey;
  queryClient: QueryClient;
}) {
  const cachedSyncStatus = queryClient.getQueryData<SyncStatus>(queryKey);
  queryClient.setQueryData(queryKey, {
    last_synced_at: cachedSyncStatus ? cachedSyncStatus.last_synced_at : null,
    duration_in_sec: cachedSyncStatus ? cachedSyncStatus.duration_in_sec : null,
    state: "running",
  });
}
