import { Outlet } from "react-router-dom";

import { PositionProvider } from "~contexts/PositionContext/PositionContext";

export function TimeTrackingOutlet() {
  return (
    <PositionProvider>
      <Outlet />
    </PositionProvider>
  );
}
