export const en = {
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  days: "days",
  day: "day",
  week: "week",
  weeks: "weeks",
  month: "month",
  months: "months",
  year: "year",
  years: "years",
  yes: "Yes",
  no: "No",
  north: "N",
  south: "S",
  west: "W",
  east: "E",
  northWest: "NW",
  northEast: "NE",
  southWest: "SW",
  southEast: "SE",
  heading: "Heading",
  speed: "Speed",
  accuracy: "Accuracy",
  timestamp: "Timestamp",
  unknown: "Unknown",
  constructionSites: "Construction sites",
  constructionSiteUnknownTitle: "Unknown",
  constructionSiteUnknownDescription: "Construction site not found.",
  allEntries: "All",
  employee: "Employee",
  addOptionPrefix: "Add",
  addOptionSuffix: "",
  myAreas: "My Areas",
  aroundPlanning: "Everything about planning",
  serviceCategory: "Service Category",
  planning: "Planning",
  planningDescription: "Arrange your construction sites.",
  planningDateRange: "Planning period",
  timeApproval: "Time Approval",
  timeApprovalDescription: "Approve your teams' time entries.",
  timeApprovalDurationIsLoading: "Loading...",
  timeApprovalArrivalDuration: "Arrival duration",
  timeApprovalDepartureDuration: "Departure duration",
  timeApprovalCommuteTypeNotAllowed: "Unknown type",
  timeApprovalCommuteTypeNoCommute: "none",
  timeApprovalCommuteTypeDriverHome: "Home - driver",
  timeApprovalCommuteTypeDriverSite: "Site - driver",
  timeApprovalCommuteTypePassengerHome: "Home",
  timeApprovalCommuteTypePassengerSite: "Site",
  absences: "Absences",
  absence: "Absence",
  absencesDescription: "Manage your absences.",
  absencesTitle: "Absences",
  absenceDateRangePickerLabel: "Date range",
  absencePeriod: "Period",
  absenceCategory: "Category",
  absenceCategorySchool: "School",
  absenceCategorySickness: "Sickness",
  absenceCategoryTraining: "Training",
  absenceCategoryVacation: "Vacation",
  absenceCategoryLeisureTimeAccount: "Leisure time account",
  absenceNote: "Note",
  addAbsenceSuccess: "Absence successfully added.",
  updateAbsenceSuccess: "Absence successfully updated.",
  deleteAbsenceSuccess: "Absence successfully deleted.",
  absenceEditButton: "Edit",
  absenceFormEditHeadline: "Edit absence",
  absenceFormAddHeadline: "Add absence",
  addAbsence: "Add absence",
  absenceInfoText: "Employee is absent for the selected time frame!",
  absenceDeleteModalTitle: "Delete absence",
  absenceDeleteModalInfo:
    "Are you sure you want to delete this absence? <br/><br/>" +
    "Times for this absence may have already been exported" +
    "or construction sites have been staffed differently due to the planned absence.",
  absenceDeleteModalConfirm: "Understood",
  absenceEmptySearchResult:
    "No absences could be found for the selected search criteria.",
  absenceCategorySelectPlaceholder: "Select category",
  absenceMinDateError: "The date can be a maximum of 1 year in the past",
  endDateAfterStartDate: "Start date must be before end date",
  timeRange: "Time Range",
  loading: "Loading",
  photoGallery: "Photo Gallery",
  projectsJournal: "Projects Journal",
  projectsJournalDescription:
    "Keep track of how well your construction sites are doing.",
  projectsJournalBreadcrumbs: "Projects Journal",
  controllingAccountSubgroupFilter: "Accounts",
  controllingAccountSubgroupAll: "All transactions",
  controllingAccountSubgroupLabelToolsInternal: "Internal",
  controllingAccountSubgroupLabelToolsExternal: "External",
  controllingAccountSubgroupLabelForeignInternal: "Internal",
  controllingAccountSubgroupLabelForeignExternal: "External",
  controllingAccountSubgroupLabelRevenueInvoiced: "Invoiced",
  controllingAccountSubgroupLabelRevenuePartiallyPaid: "Partially paid",
  controllingAccountSubgroupLabelRevenueProceeds: "Proceeds",
  controllingProjectsFilterLabel: "Projects",
  controllingProjectsFilterPlaceholder: "Select projects",
  controllingBusinessLineFilterLabel: "Business lines",
  controllingBusinessLineFilterPlaceholder: "Select business lines",
  controllingProjectsManagersFilterLabel: "Construction site manager",
  controllingProjectsClientNamesFilterLabel: "Client",
  controllingDateRangeFilterLabel: "Booking period",
  controllingProjectsNoProjectsFound:
    "No projects were found for the selected search criteria.",
  controllingProjectsProjectLong: "Project",
  controllingProjectsProject: "Project",
  controllingProjectsProjectTooltip: "Project",
  controllingProjectsStatusLong: "Status",
  controllingProjectsStatus: "Status",
  controllingProjectsStatusTooltip: "Status",
  controllingProjectsLastOutgoingInvoiceAtLong: "Last outgoing invoice",
  controllingProjectsLastOutgoingInvoiceAt: "Last out. inv.",
  controllingProjectsLastOutgoingInvoiceAtTooltip: "Last outgoing invoice",
  controllingProjectsLastIncomingInvoiceAtLong: "Last incoming invoice",
  controllingProjectsLastIncomingInvoiceAt: "Last in. inv.",
  controllingProjectsLastIncomingInvoiceAtTooltip: "Last incoming invoice",
  controllingProjectStatus_completed: "Completed",
  controllingProjectStatus_in_progress: "in progress",
  controllingProjectStatus_unstarted: "Not started",
  controllingProjectStatus_unknown: "Unknown",
  controllingProjectsProductiveHoursLong: "Productive hours",
  controllingProjectsProductiveHours: "Prod. Hours",
  controllingProjectsProductiveHoursTooltip: "Productive hours",
  controllingProjectsProfitContributionPerHourTotalLong:
    "Profit contribution per hour",
  controllingProjectsProfitContributionPerHourTotal: "PC / h",
  controllingProjectsProfitContributionPerHourTotalTooltip:
    "Profit contribution per hour",
  controllingProjectsCostsLong: "Production Costs",
  controllingProjectsCosts: "Prod. Costs",
  controllingProjectsCostsTooltip: "Production Costs",
  controllingProjectsRevenueLong: "Revenues and Received Advance Payments",
  controllingProjectsRevenue: "Rev. & Rec. Adv. Pay.",
  controllingProjectsRevenueTooltip: "Revenues and Received Advance Payments",
  controllingProjectsProfitContributionLong: "Total Profit Contribution",
  controllingProjectsProfitContribution: "PC (total)",
  controllingProjectsProfitContributionTooltip: "Total Profit Contribution",
  controllingProjectsRelativeProfitContributionLong:
    "Total Relative Profit Contribution",
  controllingProjectsRelativeProfitContribution: "PC (rel.)",
  controllingProjectsRelativeProfitContributionTooltip:
    "Total Relative Profit Contribution",
  controllingProjectsProfitContributionPercentageLong:
    "Relative Profit Contribution",
  controllingProjectsProfitContributionPercentage: "PC (rel.)",
  controllingProjectsProfitContributionPercentageTooltip:
    "Relative Profit Contribution",
  controllingProjectDetailsTotalActual: "Actual",
  controllingProjectDetailsTotalTarget: "Total Target",
  controllingProjectDetailsDelta: "Total Delta",
  controllingProjectDetailsSums: "Sums",
  controllingProjectDetailsMonths: "Months",
  controllingProjectDetailsInaccurateWarning:
    "Note that with a selected booking period filter, the target numbers cannot be calculated correctly. The numbers refer exclusively to the entire booking period.",
  controllingProjectDetailsProfitContribution: "Profit Contribution",
  controllingProjectDetailsProfitContributionTooltip: "Profit Contribution",
  controllingProjectDetailsRevenue: "Revenue",
  controllingProjectDetailsRevenueTooltip: "Revenue",
  controllingProjectDetailsCostsTotal: "Costs",
  controllingProjectDetailsCostsTotalTooltip: "Costs",
  controllingProjectDetailsCostsWages: "Wages",
  controllingProjectDetailsCostsWagesTooltip: "Wages",
  controllingProjectDetailsCostsTools: "Tool",
  controllingProjectDetailsCostsToolsTooltip: "Tool",
  controllingProjectDetailsCostsMaterials: "Material",
  controllingProjectDetailsCostsMaterialsTooltip: "Material",
  controllingProjectDetailsCostsMisc: "Misc",
  controllingProjectDetailsCostsMiscTooltip: "Misc",
  controllingProjectDetailsCostsPlants: "Plants",
  controllingProjectDetailsCostsPlantsTooltip: "Plants",
  controllingProjectDetailsCostsForeign: "Foreign",
  controllingProjectDetailsCostsForeignTooltip: "Foreign",
  controllingProjectDetailsCostsDisposal: "Disposal",
  controllingProjectDetailsCostsDisposalTooltip: "Disposal",
  controllingProjectDetailsCostsOverheads: "BGK",
  controllingProjectDetailsCostsOverheadsTooltip: "BGK",
  controllingProjectEntriesModalHeaderText: "Text",
  controllingProjectEntriesModalHeaderJournalEntryDate: "Date",
  controllingProjectEntriesModalHeaderNumber: "Number",
  controllingProjectEntriesModalHeaderCustomer: "Customer/Supplier",
  controllingProjectEntriesModalHeaderCost: "Cost",
  controllingProjectEntriesModalHeaderRevenue: "Revenue",
  controllingProjectEntriesModalHeaderEmployee: "Employee",
  controllingProjectEntriesModalHeaderWageType: "Wage type",
  controllingProjectEntriesModalHeaderWageEntryDate: "Date",
  controllingProjectEntriesModalHeaderHours: "Hours",
  controllingProjectEntriesModalHeaderWageCosts: "Wages",
  controllingProjectsUnknownEmployee: "Unknown",
  controllingProjectsFooterTotal: "Total",
  controllingProjectsFooterProjects: "projects",
  controllingProjectMetaInfoDialogTitle: "Construction site {projectNumber}",
  controllingProjectMetaInfoInformationSectionTitle: "Information",
  controllingProjectMetaInfoDescriptionLabel: "Description",
  controllingProjectMetaInfoClientsLabel: "Clients",
  controllingProjectMetaInfoBusinessLineLabel: "Business line",
  controllingProjectMetaInfoServiceCategoriesLabel: "Service categories",
  controllingProjectMetaInfoSuppliersLabel: "Suppliers",
  controllingProjectMetaInfoEmployeesLabel: "Commercial employees involved",
  controllingProjectMetaInfoUnknownEmployee: "Unknown employee: {staffNumber}",
  controllingHoursEvaluations: "Hours evaluations",
  controllingHoursEvaluationsDescription: "Hours evaluations",
  controllingHourEvaluationsPageHeader: "Hours evaluations",
  controllingHourEvaluationsChargeableHours: "Productive",
  controllingHourEvaluationsCommuting: "Commute time",
  controllingHourEvaluationsTraining: "Training",
  controllingHourEvaluationsVacation: "Vacation",
  controllingHourEvaluationsIllness: "Illness",
  controllingHourEvaluationsAdministration: "Administration",
  controllingHourEvaluationsLeisureTime: "Leisure Time",
  controllingHourEvaluationsBadWeather: "Bad Weather",
  controllingHourEvaluationsBankHolidays: "Bank Holidays",
  controllingHourEvaluationsTotal: "Total",
  controllingHourEvaluationsTotalUntilCurrentMonth: "Total until current month",
  controllingHourEvaluationsWageTypeTotal: "Total",
  controllingHourEvaluationsChargeableHoursToTotalRatio:
    "Produtive to total ratio",
  controllingHourEvaluationsTarget: "Target",
  controllingHourEvaluationsActual: "Actual",
  controllingHourEvaluationsPreviousMonth: "Previous month",
  controllingHourEvaluationsNextMonth: "Next month",
  controllingHourEvaluationsNoEmployeesHourEvaluations:
    "There are no employee hours for this hours type.",
  controllingHourEvaluationsGroupingSelectLabel: "Display by...",
  controllingHourEvaluationsGroupingSelectBusinessLine:
    "Wage type > Business line > Employee",
  controllingHourEvaluationsGroupingSelectEmployee:
    "Wage type > Employee > Business Line",
  surcharges: "Surcharge Rates",
  surchargesDescription: "Manage surcharge rates",
  surchargesBreadcrumbs: "Surcharge Rates",
  surchargesServiceCategoryDefault: "Default",
  surchargesUploadButton: "Upload file",
  surchargesUploadSuccess: "New surcharges saved",
  surchargesUploadFailed: "Error while saving new surcharges",
  bookedHours: "Booked Hours Overview",
  bookedHoursDescription: "Overview of booked hours",
  bookedHoursDateFilterLabel: "Date range",
  bookedHoursNothingToSeeTitle: "No Employees",
  bookedHoursNothingToSeeContent:
    "The selected construction site manager has no employees.",
  bookedHoursTooltipBookedHoursLabel: "Booked hours",
  bookedHoursTooltipTrackedHoursLabel: "Tracked hours",
  bookedHoursTooltipMaxWorkingHoursFullAge:
    "Maximum daily working hours of 10 hours exceeded",
  bookedHoursTooltipMaxWorkingHoursUnderage:
    "Maximum daily working hours of 8 hours for minors exceeded",
  bookedHoursTooltipMaxWeeklyWorkingHoursFullAge:
    "Maximum weekly working hours of 48 hours exceeded",
  bookedHoursTooltipMaxWeeklyWorkingHoursUnderage:
    "Maximum weekly working hours of 40 hours for minors exceeded",
  bookedHoursDifferenceBetweenTrackedAndBookedHours:
    "Difference between tracked and booked working hours",
  bookedHoursMaxWorkingHoursExceededIconLabel: "Maximum work time exceeded",
  bookedHoursOverheadCosts: "Overhead costs",
  employeeAdministration: "Employee Administration",
  employeeAdministrationDescription: "Overview and management of employees",
  employeeAdministrationFilterSelectLabel: "Show",
  employeeAdministrationSuperiorSelectOptionAll: "All",
  employeeAdministrationSuperiorSelectOptionAssignmentPending:
    "Assignment pending",
  employeeAdministrationSuperiorAssignmentMissingSingular: "Assignment pending",
  employeeAdministrationSuperiorAssignmentMissingPlural: "Assignments pending",
  employeeAdministrationEditEmployeeNotPermittedTooltip:
    "You are not permitted to edit employees. Please refer to a holding manager",
  employeeAdministrationExportPurchasingButtonLabel: "Export Purchasing",
  employeeAdministrationExportControllingButtonLabel: "Export Controlling",
  employeeAdministrationFilterSelectOptionAmbiguousAddresses:
    "Ambiguous Addresses",
  employeeAdministrationAmbiguousAddresses: "Ambiguous Addresses",
  employeeAdministrationAmbiguousAddress: "Ambiguous Address",
  employeeListNameLabel: "Name",
  employeeListJobTitleLabel: "Job Title",
  employeeListSuperiorLabel: "Superior",
  employeeListResidenceAddressLabel: "Address",
  employeeListResidenceAddressStatusLabel: "Address-Status",
  employeeListToolsCostCenterLabel: "Tools Cost Center",
  employeeListOfficeLocationLabel: "Office Location",
  employeeListAddressStatusMissing: "Missing",
  employeeListAddressStatusHigh: "✔ High",
  employeeListAddressStatusManual: "Manual",
  employeeListAddressStatusLow: "✗ Low",
  employeeListTeamsLabel: "Teams",
  employeeListActionsLabel: "Actions",
  employeeListNoTeam: "No team assigned",
  employeeListNoJobTitle: "Not Job Title",
  employeeListSuperiorMissing: "Assignment missing",
  employeeJobTitleManagment: "Managment",
  editEmployeeDialogTitle: "Edit employee",
  editEmployeeSuperiorLabel: "Superior",
  editEmployeeSuperiorNoOptions: "No superior found",
  editEmployeeCostCenterLabel: "Tools Cost Center",
  editEmployeeSuccessFeedback: "Employee was saved",
  employeeValidationTeamsMustBelongToSameTenant:
    "Teams must belong to the same tenant",
  employeeValidationSuperiorOfManagerMustBeAHoldingManager:
    "Superior of manager must be a holding manager",
  employeeValidationSuperiorOfNonManagerMustBeSameTenant:
    "Superior must be from the same tenant",
  employeeValidationSuperiorCannotBeSelf:
    "Superior cannot be the employee itself",
  employeeValidationSuperiorCannotBeCircular:
    "Superior chain can not be circular",
  toolAbbreviationLabel: "Abbreviation",
  toolAbbreviationPrefixLabel: "Abbreviation Prefix",
  toolAttachmentsLabel: "Attachments",
  toolBillableFromLabel: "Billable from",
  toolCategoryLabel: "Category",
  toolCommentLabel: "Comment",
  toolConstructionYearLabel: "Build Year",
  toolCostCenterLabel: "Cost Center",
  toolDailyRateLabel: "Daily Rate",
  toolDecommissionButton: "Decommission",
  toolDecommissionCommentLabel: "Commissioning Comment",
  toolDecommissionReason: "Reason",
  toolDecommissionReasonInventoryShrinkage: "Inventory shrinkage",
  toolDecommissionReasonLabel: "Decommission Reason",
  toolDecommissionReasonSale: "Sale",
  toolDecommissionReasonScrap: "Scrap",
  toolDecommissionReasonTheft: "Theft",
  toolDecommissionReasonUnknown: "Unknown",
  toolDecommissionReasonReturnAfterLeasing: "Return after leasing",
  toolDecommissionReasonLongTermRentalEnded: "Long-term rental ended",
  toolDecommissionReasonError: "Error",
  toolDecommissionedAtLabel: "Decommission Date",
  toolDecommissioning: "Decommissioning",
  toolDecommissioningModalCommentLabel: "Comment",
  toolDecommissioningModalDatePickerLabel: "Decommissioning date",
  toolDecommissioningModalSubmit: "Decommission",
  toolDecommissioningModalTitle: "Decommission tool",
  toolDecommissioningScheduledSuccessToast:
    "Successfully scheduled decommissioning for Tool {abbr}.",
  toolDecommissioningSuccessToast: "Tool {abbr} successfully decommissioned.",
  toolDetailsButton: "Details",
  toolDuplicateButton: "Duplicate",
  toolFilterAllTools: "All tools",
  toolFilterManagersLabel: "Managers",
  toolFilterMyResponsibilities: "Me",
  toolFilterMyTools: "My tools",
  toolFilterResponsibleEmployeeLabel: "Responsible",
  toolFilterTenantsLabel: "Tenants",
  toolFilterWithoutResponsibleEmployee: "Nobody",
  toolFinancingToLabel: "Financing To",
  toolFinancingTypeLabel: "Financing Type",
  toolFormAbbreviationConflict:
    "This abbreviation is already taken or the schema is incorrect.",
  toolFormBasicInfoHeadline: "Basic Information",
  toolFormBothOrNoneError: "Both or none of the dates must be set",
  toolFormConstructionYearError: "Build year is invalid",
  toolFormCoreDataTitle: "Core Data",
  toolFormEditHeadline: "Edit {name}",
  toolFormMiscDataTitle: "Misc Data",
  toolFormPurchaseDataTitle: "Purchase Data",
  toolFormSerialNumberDuplicateError:
    "The serial number {serialNumber} already exists in the tools {tools}. Do you really want to use it?",
  toolFormToolIdConflict: "This tool ID is already taken.",
  toolHistoryButton: "History",
  toolHistoryChangesNo: "No",
  toolHistoryChangesYes: "Yes",
  toolHistoryModalTableHeaderDate: "Date",
  toolHistoryModalTableHeaderDescription: "Description",
  toolHistoryModalTableHeaderModifiedBy: "Modified by",
  toolHistoryModalTableHeaderTime: "Time",
  toolHistoryModalTableHeaderType: "Type",
  toolHistoryModalTitle: "Tool history",
  toolHistoryToolWasCreated: "The tool was created",
  toolHistoryTypeChipCreated: "Created",
  toolHistoryTypeChipResponsibilityReturned: "Returned",
  toolHistoryTypeChipResponsibilityTaken: "Taken",
  toolHistoryTypeChipUpdated: "Updated",
  toolHistoryValueChangedFrom: 'changed from "{old}" to ---',
  toolHistoryValueChangedFromTo: 'changed from "{old}" to "{new}"',
  toolHistoryValueChangedTo: 'changed to "{new}"',
  toolIdLabel: "ID",
  toolInsuranceLineLabel: "Insurance Line",
  toolInsuranceToolTypeLabel: "Insurance Tool Type",
  toolInsuranceTypeLabel: "Insurance Type",
  toolIsBillableLabel: "Billable",
  toolIsNotBillableLabel: "Not Billable",
  toolLicensePlateNumberLabel: "License Plate Number",
  toolListAbbreviationLabel: "Abbreviation",
  toolListActionsLabel: "Actions",
  toolListIsBillable: "Billable",
  toolListIsNotBillable: "Not Billable",
  toolListPreviousResponsibleEmployee: "Previous",
  toolListSerialNumber: "SN:",
  toolListTenantLabel: "Tenant",
  toolListToolId: "ID",
  toolListTypeLabel: "Tool Type",
  toolManagement: "Tool Management",
  toolManagementDescription: "Take responsibility for your tools.",
  toolManagerLabel: "Manager",
  toolManufacturerLabel: "Manufacturer",
  toolMinuteRateLabel: "Minute Rate",
  toolNameLabel: "Name",
  toolOperatingHoursLabel: "Operating Hours",
  toolPowerLabel: "Power",
  toolPurchaseDateLabel: "Purchase Date",
  toolPurchasePriceLabel: "Purchase Price",
  toolQueryOperatingHoursLabel: "Query Operating Hours",
  toolRecommissionButton: "Recommission",
  toolRecommissioningModalSubmit: "Recommission",
  toolRecommissioningModalTitle: "Recommission tool",
  toolRecommissioningSuccessToast: "Tool {abbr} successfully recommissioned.",
  toolResponsibleEmployeeLabel: "Responsible Employee",
  toolReturnResponsibility: "Return Tool",
  toolReturnResponsibilitySuccess: "Tool {abbr} returned.",
  toolSerialNumberLabel: "Serial number",
  toolSizeLabel: "Size",
  toolSupplierLabel: "Supplier",
  toolTakeAndReturnResponsibilityHint:
    "Will be taken or returned automatically when tracking times.",
  toolTakeResponsibility: "Take Responsibility",
  toolTakeResponsibilitySuccess: "Tool {abbr} taken.",
  toolTemporarilyDecommissionedFromLabel: "Temporarily Decommissioned From",
  toolTemporarilyDecommissionedToLabel: "Temporarily Decommissioned To",
  toolTenantLabel: "Tenant",
  toolToolIdLabel: "Tool ID",
  toolToolTypeLabel: "Tool Type",
  toolToolsCostCenterLabel: "Tool Cost Construction Site",
  toolTypeDesignationLabel: "Type Designation",
  toolsAndDevices: "Tools and Devices",
  toolsCostCenters: "Tools cost centers",
  toolsCostCentersDescription: "Assign tools cost centers to managers.",
  toolsCostCentersSuccessToast: "Tools cost center successfully assigned.",
  toolsCostCentersTableCostCenter: "Tools cost center",
  toolsCostCentersTableName: "Name",
  exportTools: "Export tool list",
  exportToolAccountingRecords: "Export tool accounting records",
  errorExportingTools:
    "There was an error exporting the tool list. Please try again later.",
  errorExportingToolAccountingRecords:
    "There was an error exporting the tool accounting records. Please try again later.",
  addTool: "Add New Tool",
  addToolSuccess: "Tool {abbr} successfully created",
  editToolSuccess: "Tool {abbr} successfully edit",
  teamAdministration: "Team administration",
  teamAdministrationDescription: "Manage your teams.",
  monitoring: "Monitoring",
  monitoringDescription: "Observe active time trackings.",
  timesAndTools: "My time entries and tools",
  tasks: "My tasks",
  tasksEmpty: "All clear. Keep on!",
  tasksTimeApprovalTitleHtml: `
    <strong>Time entries</strong> are waiting for your <strong>approval</strong>
  `,
  tasksTimeApprovalLink: "Go to time approvals",
  tasksEmployeeAdministrationMissingSuperiorsTitleHtml: `
    <strong>Employees</strong> want <strong>superiors</strong>
  `,
  tasksEmployeeAdministrationAmbiguousAddressesTitleHtml: `
    <strong>Private addresses</strong> should be <strong>more precise</strong>
  `,
  tasksEmployeeAdministrationLink: "Go to employee administration",
  takePhoto: "Take Photo",
  timeTracking: "Time Tracking",
  timeTrackingDescription: "Track your time.",
  appName: "grewe-gruppe App",
  startPage: "Start Page",
  breadcrumb: "Breadcrumb",
  maintenance: "Maintenance",
  assignments: "Assignments",
  workload: "Workload",
  columnView: "Column View",
  listView: "List View",
  add: "Add",
  remove: "Remove",
  discard: "Discard",
  edit: "Edit",
  save: "Save",
  saveAndContinue: "Save & continue",
  cancel: "Cancel",
  back: "Back",
  saved: "Saved",
  team: "Team",
  noTeamAssignment: "No team assignment...",
  teamAssignmentCollapseAll: "Collapse all team assignments",
  teamAssignmentExpandAll: "Expand all team assignments",
  teamAssignmentHeadingNewPlaceholder: "New team assignment",
  teamAssignmentHeadingTeamPlaceholder: "Please select a core team...",
  teamAssignmentHeadingWorkPackagePlaceholder:
    "Please select a work package and time period...",
  teamAssignmentHeadingCopyLabel: "Copy team assignment",
  teamAssignmentHeadingDeleteLabel: "Remove team assignment",
  teamAssignmentHeadingSerialDateLabel: "Add serial date",
  teamAssignmentSerialDateDialogTitle: "Add serial date",
  teamAssignmentSerialDateDialogDateRangeLabel: "From date range",
  teamAssignmentSerialDateDialogrepetitionIntervalLabel: "Repeat every",
  teamAssignmentSerialDateDialogRepetitionWeekdaysLabel: "Repeat on",
  teamAssignmentSerialDateDialogEndHeader: "End",
  teamAssignmentSerialDateDialogEndOnYearEndRadioLabel:
    "At the end of the year",
  teamAssignmentSerialDateDialogEndOnDateRadioLabel: "On",
  teamAssignmentSerialDateDialogEndAfterNumberOfDatesRadioLabel: "After",
  teamAssignmentSerialDateDialogEndAfterNumberOfDatesLabelSingular:
    "repetition",
  teamAssignmentSerialDateDialogEndAfterNumberOfDatesLabelPlural: "repetitions",
  teamAssignmentSerialDateDialogDoneButtonLabel: "Done",
  teamAssignmentSerialDateDialogMonthyTypeOptionDate: "Monthly on {date}.",
  teamAssignmentSerialDateDialogMonthyTypeOptionDay:
    "Montly on {weekNumber}. {weekday}",
  teamAssignmentSerialDateDialogMonthyTypeOptionDayLastWeek:
    "Montly on the last {weekday}",
  teamAssignmentSerialDateDialogEndOnDateMustBeAfterStartDateError:
    "Date must be after the start date of the date range",
  teamAssignmentSerialDateDialogSuccessToast: "{count} plannings were created.",
  teamAssignmentSerialDateDialogErrorToast:
    "Creation was cancelled as too many plannings (more than {count}) would be created.",
  coreTeam: "Core team",
  selectCoreTeam: "Select core team...",
  noCoreTeam: "No core team selected...",
  additionalResources: "Additional Employees or Tools...",
  selectAdditionalResources: "Search Employees or Tools...",
  saveTeam: "Save Team",
  manageTeam: "Manage Team",
  searchTeams: "Search Teams",
  teamPersonOrTool: "Team, Person or Tool",
  workPackage: "Work package",
  workPackageAdministration: "Work packages",
  workPackageAdministrationDescription: "Manage your work packages.",
  workPackageAdministrationColumnsWorkPackage: "Work package",
  workPackageAdministrationColumnsManager: "Construction site manager",
  workPackageAdministrationColumnsServiceCategory: "Service category",
  workPackageAdministrationColumnsWageType: "Wage type",
  workPackageAdministrationColumnsLocation: "Location",
  workPackageAdministrationColumnsNote: "Note for the team",
  workPackageAdministrationColumnActions: "Actions",
  workPackageAdministrationConstructionSiteLink: "Go to construction site",
  workPackageAdministrationSearchPlaceholder:
    "Search construction site or work package...",
  workPackageAdministrationTabAll: "All ({count})",
  workPackageAdministrationTabInvalid: "Invalid ({count})",
  workPackageAccountingFieldsUnchangeableInfo:
    "Some fields cannot be changed, as there are already time trackings for this work package.",
  workPackageChargeableHoursOnlyFieldsDisabledInfo:
    "Some fields cannot be used with the selected wage type and are therefore disabled.",
  workPackageValidationAccountingFieldsCannotBeModified:
    "This field cannot be changed, as there are already time trackings for this work package.",
  workPackageValidationCommuteExpensesOnlyWithChargeableHours:
    "Commuting expenses can only be enabled for productive hours",
  selectWorkPackage: "Select work package",
  noWorkPackage: "No work package selected...",
  noWorkPackageHelperText:
    "Du musst ein Arbeitspaket wählen oder neu anlegen...",
  addWorkPackage: "New work package",
  addWorkPackageFromTextbox: "Add work package {workPackage}",
  addWorkPackageSuccess: "Work package successfully created",
  editWorkPackage: "Edit time account",
  editWorkPackageCopyClientAddressTooltip: "Click to copy the client address.",
  editingWorkPackageCopyClientAddressSuccessToast:
    "The client address has been copied to the clipboard.",
  workHoursPerDay: "Work hours per day",
  numDays: "No. days",
  from: "from",
  to: "to",
  on: "on",
  dateRange: "Date range",
  addTeamAssignmentBuilder: "add team assignment",
  invalidDate: "Invalid Date",
  invalidDateRange: "Start date must be before end date",
  invalidDateWithoutYear: "Invalid Date. Please enter in the format DD.MM.",
  invalidLicensePlateNumber: "License plate number is invalid",
  invalidLocation: "Address is invalid",
  dateInPast: "Date must not be in the past.",
  numberBetween: "Please enter a valid number.",
  required: "Required",
  optional: "optional",
  maxLength: "Maximum length: %{maxLength} tokens",
  noOptions: "No entries found",
  trackingCurrent: "Today's work packages",
  constructionSiteManager: "Construction Site Manager",
  managerSelectAllOption: "All",
  timeEntryStatusOpen: "Open",
  timeEntryStatusManagerAccount: "Manager account",
  timeEntryStatusApproved: "Approved",
  timeEntryStatusExported: "Paid out",
  timeEntryStatusDeleted: "Deleted",
  timeEntryStatusConflict: "Conflict",
  timeEntryStatusConflicts: "Conflicts",
  timeEntryCommuteAnomaly: "Commute Anomaly",
  timeEntryCommuteAnomalies: "Commute Anomalies",
  constructionSiteListUnplanned: "Unplanned",
  constructionSiteListUnplannedFortnight: "(begin within the next 14 days)",
  constructionSiteListPlanned: "Planned",
  constructionSiteListDone: "Done",
  constructionSiteListInProgress: "In progress",
  constructionSiteListUnplannedWarning: "Planned starting date not set",
  trackingAlreadyStoppedTitle: "Time tracking already stopped",
  trackingAlreadyStoppedDescription:
    "Your time tracking is already stopped. This may happen by your construction site manager",
  trackingAlreadyStoppedButton: "Understood",
  trackingConstructionSiteManager: "My construction site manager",
  trackingConstructionSiteManagerAccount: "Construction site manager account",
  trackingConstructionSiteManagerAccountInfo:
    "Track your times to this construction site manager account if no work package exists for your construction site yet.",
  trackingConstructionSiteManagerDialogTitle: "Capture task",
  trackingConstructionSiteManagerFormCustomerName:
    "Customer name / Contact person on site",
  trackingConstructionSiteManagerFormManagerName: "Construction site manager",
  trackingConstructionSiteManagerFormStreet: "Street",
  trackingConstructionSiteManagerFormHouseNumber: "House no.",
  trackingConstructionSiteManagerFormZip: "Zip code",
  trackingConstructionSiteManagerFormCity: "City",
  trackingConstructionSiteManagerFormPhone: "Phone number",
  trackingConstructionSiteManagerFormDescription:
    "Task / Description of construction site",
  trackingConstructionSiteManagerTeamName:
    "Tracking on construction site manager account",
  trackingSummaryModalTitle: "Summary",
  trackingTimeEntrySummaryModalTitle: "Recorded times",
  trackingTimeEntrySummaryTableNet: "Net",
  trackingSummaryModalPerformedActivities: "Performed activities",
  trackingSummaryModalNote: "Note",
  trackingSummaryModalContinueButton: "Back",
  trackingSummaryModalStopButton: "End",
  trackingSplitModalTitle: "Divide time tracking",
  trackingSplitModalDescription: `Your time time tracking has passed midnight. Due to technical reasons
  your current time time tracking must be divided into two day-related time trackings
  be divided.
    <br />
    Your current time tracking counts the time since 00:00, yours from yesterday
  Times until 11:59 p.m. are summarized as follows:`,
  trackingSplitModalConfirm: "Continue!",
  trackingAutoStopPerformedActivities: "-",
  trackingAutoStopNote: "Time tracking was stopped automatically",
  trackingForceStopModalTitle: "Stop Time Tracking",
  trackingForceStopModalDescription:
    "The trackings will be stopped with a duration of zero seconds. Further modifications can be done after stopping in the time approval area.",
  trackingForceStopModalConfirm: "Beenden",
  trackingSwitchWorkPackageDialogTitle: "Change work package",
  trackingSwitchWorkPackageDialogContent:
    "If you confirm, all additional employees and tools will be booked out of the current time booking.",
  trackingSwitchWorkPackageDialogConfirmButton: "Confirm",
  trackingSwitchNotPossibleError:
    "Unfortunately, it is not possible to change the time recording directly. Please stop the running time tracking first.",
  trackingOverviewTrainingTitle: "Training",
  trackingOverviewTrainingInfo: "Click here to enter futher details.",
  trackingTrainingBannerTitle: "Training",
  trackingUnknownBannerTitle: "Unknown task",
  trackingTrainingParticipants: "Participants",
  trackingTrainingModalTitle: "Record further training details",
  trackingTrainingFormApprovalBy: "Approval by",
  trackingTrainingFormNote: "Remark",
  trackingTrackingEmployeeSearchLabel: "Search employees...",
  dailySummaryBreadcrumbsHeader: "Today's time entries",
  dailySummaryTableHeading: "Total",
  dailySummaryTableName: "Name",
  dailySummaryTableTime: "Time",
  dailySummaryTableBreak: "Break",
  dailySummaryTableNet: "Net",
  dailySummaryTableError:
    "Time summary can not be displayed because there are errors in the time entries.",
  dailySummaryTableNoTimeTrackings:
    "You don't have any completed time trackings for.",
  dailySummaryAccordionEntriesFrom: "time entries from",
  dailySummaryBack: "Back",
  trackingRecent: "Recently used",
  startTimeTracking: "Start timer",
  startTimeTrackingSuccess: "Timer started",
  splitTrackingSuccess:
    "We have splitted your time tracking. You now see the duration since the split. No worries - your previous time tracking is save!",
  stopTimeTracking: "Stop",
  stopTimeTrackingSuccess: "Timer stopped",
  timeTrackingCommuteDialogStartTitle: "Determine arrival",
  timeTrackingCommuteDialogStartStepOneTitle:
    "Where are you coming from today?",
  timeTrackingCommuteDialogStartStepTwoTitle: "Who drove?",
  timeTrackingCommuteDialogStartLocationTitle: "Starting location",
  timeTrackingCommuteDialogStopTitle: "Determine departure",
  timeTrackingCommuteDialogStopStepOneTitle: "Where are you going?",
  timeTrackingCommuteDialogStopStepTwoTitle: "Who is driving back?",
  timeTrackingCommuteDialogStopLocationTitle: "Destination",
  timeTrackingCommuteDialogStepCounter: "Step {current} of {total}",
  timeTrackingCommuteDialogHomeIconTitle: "Home",
  timeTrackingCommuteDialogSiteIconTitle: "Site",
  timeTrackingCommuteDialogNoCommuteIconTitle: "No Commute Payment",
  timeTrackingCommuteDialogDriverIconTitle: "Driver",
  timeTrackingCommuteDialogNextButton: "Next",
  timeTrackingCommuteDialogBackButton: "Back",
  timeTrackingCommuteDialogCancelButton: "Cancel",
  timeTrackingCommuteDialogStartTrackingButton: "Done",
  timeTrackingCommuteDialogStopTrackingButton: "Finish",
  timeTrackingQuickCheckInCheckOutButtonLabel_arrival_no_commute:
    "No commute expenses",
  timeTrackingQuickCheckInCheckOutButtonLabel_arrival_driver_home:
    "<b>From home</b> as <b>driver</b>",
  timeTrackingQuickCheckInCheckOutButtonLabel_arrival_driver_site:
    "<b>From site</b> as <b>driver</b>",
  timeTrackingQuickCheckInCheckOutButtonLabel_arrival_passenger_home:
    "<b>From home</b> as <b>passenger</b>",
  timeTrackingQuickCheckInCheckOutButtonLabel_arrival_passenger_site:
    "<b>From site</b> as <b>passenger</b>",
  timeTrackingQuickCheckInCheckOutButtonLabel_departure_no_commute:
    "No commute expenses",
  timeTrackingQuickCheckInCheckOutButtonLabel_departure_driver_home:
    "<b>To home</b> as <b>driver</b>",
  timeTrackingQuickCheckInCheckOutButtonLabel_departure_driver_site:
    "<b>To site</b> as <b>driver</b>",
  timeTrackingQuickCheckInCheckOutButtonLabel_departure_passenger_home:
    "<b>To home</b> as <b>passenger</b>",
  timeTrackingQuickCheckInCheckOutButtonLabel_departure_passenger_site:
    "<b>To site</b> as <b>passenger</b>",
  timeTrackingSince: "since",
  addResource: "Add {resource}",
  editResource: "Edit {resource}",
  removeResource: "Remove {resource}",
  open: "Open",
  close: "Close",
  clear: "Clear",
  signIn: "Sign in with microsoft",
  signOut: "Sign out",
  syncButtonTooltip: "Sync App Data",
  syncButtonConstructionSitesTooltip: "Sync construction site data",
  syncButtonEmployeesTooltip: "Sync employee data",
  syncButtonControllingDataTooltip: "Sync controlling data",
  syncButtonAppDataTooltip: "Sync app data",
  syncMenuTitle: "Synchronisation status",
  syncMenuEmployeesTitle: "Employee data",
  syncMenuConstructionSitesTitle: "Construction site data",
  syncMenuControllingTitle: "Controlling data",
  syncMenuAppDataTitle: "App data",
  syncMenuLastSyncedAt: "Last synced at:",
  syncMenuLastSyncUnknown: "Synchronisation status unknown",
  syncMenuMaxTimeInformation: "Can take up to {maxTime} min...",
  syncMenuSyncRunning: "Sync running",
  syncMenuSyncFailed: "Last sync failed",
  syncMenuFooter: "If you are experiencing errors, please contact",
  syncRefetchSuccess: "Sync successful",
  notFoundHeader: "Page Not Found",
  notFoundText: "We are sorry, the page you requested cannot be found.",
  genericErrorPageHeader: "An Error has occurred",
  genericErrorPageText:
    "Please reload the page. We already generated an error message in the background. If the error reoccurs, please contact app@grewe-gruppe.de.",
  forbiddenHeader: "Missing Access Rights",
  forbiddenText: "You don't have access rights.",
  forbiddenFooter:
    "If you think this is an error, pleace contact your administrators.",
  forbiddenContacts: "Bea Timmermann, Dominik Elsbroek",
  tenantForbiddenHeader: "Missing access rights for {forbiddenTenantName}",
  tenantForbiddenText:
    "You are currently missing access rights for <strong>{forbiddenTenantName}</strong>.",
  tenantForbiddenLink: "Go to {allowedTenantName}",
  geolocationError: "Please allow access to your location to use this feature",
  teams: "Teams",
  editTeam: "Edit team",
  editSuccess: "Changes successfully saved",
  addTeam: "Add new team",
  addTeamSuccess: "Team successfully created",
  deleteTeamSuccess: "Team successfully deleted",
  teamFormName: "Name",
  teamFormCancel: "Cancel",
  teamFormSave: "Save",
  teamFormDelete: "Delete",
  teamFormItemMultiSelectorLabel: "Search Employees or Tools...",
  teamFormItemMultiSelectorError: "Choose at least one employee or tool.",
  teamConstructionSiteManagerLabel: "Construction Site Manager",
  teamConstructionSiteManagerError:
    "Please choose a construction site manager.",
  unknownError:
    "An unknown error has occurred. Please try again. We already generated an error message in the background. If the error reoccurs, please contact app@grewe-gruppe.de.",
  "name has already been taken": "Name has already been taken",
  unknownEmployee: "Unknown employee",
  unknownTenant: "Unknown tenant",
  unknownCategory: "Unknown category",
  unknownCategoryTooltip: "Unknown category",
  unknownConstructionSiteManager: "Unknown construction site manager",
  unknownWorkPackage: "Unknown work package",
  unknownConstructionSite: "Unknown construction site",
  lv_identifier_taken: "Identifier already taken.",
  teamFormCurrentlyPartOf: "Is currently part of the team",
  teamFormAddPermanently: "Add permanently from current team",
  teamFormAddAll: "Add all",
  teamFormAllAdded: "All changes applied",
  collapseRow: "Collapse row",
  expandRow: "Expand row",
  teamlessResources: "Employees and Tools Without Team",
  teamless: "Not assigned to a team",
  tool: "Tool",
  tools: "Tools",
  taskCustomer: "Customer",
  taskDescription: "Description",
  taskPerformedActivities: "Performed activities",
  taskNote: "Note",
  name: "Name",
  editedAt: "Last edit",
  updateAvailable:
    "There is an update available. Please update to make sure that the app keeps working.",
  updateNow: "Update",
  invalidConstructionSites: "Invalid construction sites",
  invalidConstructionSitesDescription: "Find invalid construction sites.",
  invalidProjects: "Invalid projects",
  invalidProjectsDescription: "Find invalid projects.",
  invalidProjectsInvalidTenantNumberReason:
    "Project has an invalid project number.",
  invalidProjectsUnknownTenantNumberReason: "Project has an unknown tenant.",
  invalidProjectsUnknownReason:
    "Project is invalid. The reason '%{reason}' is unknown.",
  invalidTools: "Invalid tools",
  invalidToolsDescription: "Find invalid tools data.",
  invalidToolsNameMissing: "Name missing",
  invalidToolsTenantMissing: "Tenant missing",
  invalidToolsManagerMissing: "Manager missing",
  invalidToolsCategoryMissing: "Category missing",
  invalidToolsToolIdMissing: "Tool ID missing",
  invalidToolsDailyRateMissing: "Daily rate missing",
  invalidToolsMinuteRateMissing: "Minute rate missing",
  invalidToolsAllTenants: "All tenants",
  workPackageFormName: "Name",
  workPackageFormCalculatedHours: "Calculated hours",
  workPackageFormPlannedHours: "Planned hours",
  workPackageFormCommuteExpensesEnabledLabel: "Commuting expenses are paid",
  workPackageFormServiceCategoryLabel: "Service Category",
  workPackageFormLocationLabel: "Location",
  workPackageFormNoteLabel: "Note on execution to the team",
  workPackageFormServiceCategoryError: "Please choose a service category.",
  workPackageFormConstructionSiteManagerLabel: "Construction Site Manager",
  workPackageFormConstructionSiteManagerError:
    "Please choose a construction site manager.",
  workPackageFormWageTypeLabel: "Wage type",
  timeApprovalConstructionSiteManagerAccount:
    "Construction site manager account",
  timeApprovalNoLocation: "No location available",
  timeApprovalOutdatedLocation: "Location is outdated",
  timeApprovalTableEmployee: "Employee or tool",
  timeApprovalTableArrivalCommute: "Arrival",
  timeApprovalTableDepartureCommute: "Departure",
  timeApprovalTableFrom: "from",
  timeApprovalTableTo: "to",
  timeApprovalTableTimeRange: "from - to",
  timeApprovalTableBreak: "Break",
  timeApprovalTableNet: "Net",
  timeApprovalTableTask: "Work package / Time entry account",
  timeApprovalShowLocation: "Show location on map.",
  timeApprovalLocationTooFarAway:
    "Distance of start of booking to work location is unusually far away ({distanceToWorkLocation}).",
  timeApprovalWorkPackageLocationMissing:
    "Distance cannot be calculated because no address is stored in the work package.",
  timeApprovalApprovedTooltip:
    "Approved at {approvedDate} o'clock by {approvedBy}",
  timeApprovalPaidOutTooltip:
    "Paid out at {exportedAt} o'clock by {exportedBy}",
  timeApprovalDeletedTooltip:
    "This time entry was deleted at {deletedAt} o`clock by {deletedBy}",
  timeApprovalDeleteButtonTooltipResourceName: "time entry of {employee}",
  timeApprovalOverlappingTooltip:
    "Time entry cannot be approved as there are conflicts with other time entries.",
  timeApprovalCreateTimeTrackingDialogDateLabel: "Date",
  timeApprovalFiltersDateRange: "Date range",
  timeApprovalFiltersGrouping: "Grouping",
  timeApprovalFiltersGroupingTeam: "Team",
  timeApprovalFiltersGroupingWorkPackage: "Work Package / Time entry account",
  timeApprovalFiltersGroupingEmployee: "Employee",
  timeApprovalFiltersGroupNameTeam: "Team name",
  timeApprovalFiltersGroupNameWorkPackage: "Work package name",
  timeApprovalFiltersGroupNameEmployee: "Employee name / number",
  timeApprovalFiltersSearch: "Search",
  timeApprovalFiltersStatus: "Status",
  timeApprovalColumnsCommute: "Commuting time",
  timeApprovalColumnsEmployee: "Employee",
  timeApprovalColumnsFrom: "From",
  timeApprovalColumnsTo: "To",
  timeApprovalColumnsBreak: "Break",
  timeApprovalColumnsNet: "Net",
  timeApprovalColumnsTeam: "Team",
  timeApprovalColumnsTask: "Work package / Time entry account",
  timeApprovalColumnsStatus: "Status",
  timeApprovalColumnsActions: "Actions",
  timeApprovalCellTotal: "Total",
  timeApprovalTaskInformation: "Task information",
  timeApprovalTaskModalAssignWorkPackageTitle: "Assign Work Package",
  timeApprovalTaskModalAssignWorkPackageButton: "Assign",
  timeApprovalActionButtonsTaskModalTooltip: "Assign Work Package",
  timeApprovalActionButtonAssignTooltip: "Assign",
  timeApprovalActionButtonsApproveTooltip: "Approve",
  timeApprovalActionButtonsUnapproveTooltip: "Unapprove",
  timeApprovalActionButtonsEditTooltip: "Edit Time Entries",
  timeApprovalEditTimeEntriesFormModalTitle: "Edit Time Entries",
  timeApprovalCreateTimeEntriesFormModalTitle: "Create Time Entries",
  timeApprovalNoTeam: "(Without Team)",
  timeApprovalTimeEntriesFormModalSave: "Save",
  timeApprovalTimeEntriesFormModalSaveWithApprove: "Save And Approve",
  timeApprovalTimeEntriesFormModalSecondPrecisionLabel: "Second Precision",
  timeApprovalNewAbsenceFormModalTitle: "Enter Absence",
  timeApprovalNewAbsenceFormModalHint:
    "To plan absences over several days, use the",
  timeApprovalNewAbsenceFormModalHintLink: "absence management",
  timeApprovalNewAbsenceFormEmployeePlaceholder: "Select employee",
  timeApprovalNewTrainingFormModalTitle: "New training",
  timeApprovalSpeedDialNewWorkingHours: "New Working Hours",
  timeApprovalSpeedDialNewAbsence: "New Absence",
  timeApprovalSpeedDialNewTraining: "New Training",
  timeApprovalNetDurationWholeDayTooltip:
    "The underlying contractual working hours of the respective employee are taken as the net time for absence.",
  timeApprovalMultipleArrivalCommuteTooltip:
    "For this employee, there are two time trackings on one day for which a departure point has been selected for the calculation of commuting expenses.",
  timeApprovalMultipleDepartureCommuteTooltip:
    "For this employee, there are two time trackings in one day for which a destination has been selected for the calculation of travel expenses.",

  timeApprovalMultipleDriversOnTimeTrackingArrivalTooltip:
    "For this time tracking, more than one employee was selected as the driver for the outward journey.",
  timeApprovalMultipleDriversOnTimeTrackingDepartureTooltip:
    "For this time tracking, more than one employee was selected as the driver for the return trip.",
  timeApprovalArrivalCommuteTimeBiggerThanTrackingSwitchTimeTooltip:
    "The commute time is bigger than the last check out and the check in for this work package.",
  timeApprovalDepartureCommuteTimeBiggerThanTrackingSwitchTimeTooltip:
    "The commute time is bigger than der check out for this work package and the next check in.",
  invalidDateRangeInfo: `
    Please select a valid period.
    <br />
    To avoid excessively long loading times, you can only set a time period
    of <strong>maximum 6 months</strong>.
  `,
  timeApprovalEmptyResultInfo:
    "No time entries were found for the selected search criteria.",
  timeApprovalActionButtonsHistoryTooltip: "Show change history",
  timeApprovalToolTooltip: "Tools are approved automatically.",
  timeApprovalConfirmDialogTitle: "Exceeding the maximum total work time",
  timeApprovalConfirmDialogText:
    "The following employees have a total booked time over 10 hours:",
  timeApprovalConfirmDialogOk: "Approve anyways",
  timeApprovalConfirmDialogCancel: "Cancel",
  timeApprovalFooterTotal: "Total",
  timeApprovalFooterTotalUnit: "Time Entries",
  timeApprovalFooterEmployeesCount: "# Booked Employees",
  timeApprovalFooterEmployeesCountLong: "Number of Booked Employees",
  timeApprovalFooterToolsCount: "# Booked Tools",
  timeApprovalFooterToolsCountLong: "Number of Booked Tools",
  timeApprovalFooterProductiveHours: "𝛴 Productive Hours",
  timeApprovalFooterProductiveHoursLong: "Sum of Productive Hours",
  timeApprovalFooterNonProductiveHours: "𝛴 Other Hours",
  timeApprovalFooterNonProductiveHoursLong: "Sum of Other Hours",
  timeApprovalFooterToolsHours: "𝛴 Tools Hours",
  timeApprovalFooterToolsHoursLong: "Sum of Tools Hours",
  setWorkPackageFormWorkPackageLabel: "Work package",
  setWorkPackageFormWorkPackageError: "Please choose a work package",
  editTimeEntryAddEntry: "Add entry",
  editTimeEntryErrorOverlapping: "This time overlaps other time entries",
  editTimeEntryConflictTooltipHeader:
    "Overlaps with the following time entries:",
  editTimeEntryErrorRequired: "Required",
  editTimeEntryErrorStartTimeGreaterThanStopTime: "Must be smaller than end",
  editTimeEntryErrorStopTimeLowerThanStartTime: "Must be greater than start ",
  editTimeEntryNoEmployeeAvailable: "No employee/tool available",
  editTimeEntryNoWorkPackagesAvailable: "No workpackages available",
  editTimeEntrySelectEmployee: "Select employee/tool",
  editTimeEntrySelectWorkPackage: "Select workpackages",
  editTimeEntryUnknownWorkPackage: "Unknown workpackage",
  loggedInAs: "Logged in as",
  loginAnotherUser: "Login another user",
  loginPageTitle: "Welcome to grewe-gruppe",
  loginPageTextHtml: `
    <p>
      Sign in with your Microsoft Account and start using our App! Any concerns, feedback or questions
      can be sent to <a href="mailto:app@grewe-gruppe.de">app@grewe-gruppe.de</a>.
    </p>
  `,
  moreLoadingInfo:
    "Loading takes longer than expected. Please be patient. If it takes a long time, check if you have an internet connection.",
  selectForeman: "Choose a team leader",
  foremanRequired: "Please choose a team leader.",
  timeEntryExport: "Export times",
  timeEntryExportDescription: "Export times.",
  timeEntryExportDateRangeLabel: "Date range",
  timeEntryExportTenantLabel: "Tenant",
  timeEntryExportTenantNoOptions: "Not tenants available",
  timeEntryExportIncludeAlreadyExportedLabel:
    "Also include times already exported",
  timeEntryExportSubmitButtonLabel: "Download Times",
  timeEntryExportFileNameFromPlaceholder: "{from}",
  timeEntryExportFileNameUntilPlaceholder: "{Until}",
  timeEntryExportFileNameTenantPlaceholder: "{tenant}",
  timeEntryExportStepOneTitle: "Step 1",
  timeEntryExportStepOneSubtitle: "Choose tenant",
  timeEntryExportStepTwoTitle: "Step 2",
  timeEntryExportStepTwoSubtitle: "Configure export",
  timeEntryExportStepThreeTitle: "Step 3",
  timeEntryExportStepThreeSubtitle: "Download times as csv file",
  timeEntryExportPreviewTitle: "Export preview",
  timeEntryExportPreviewEmptyResultTitle: "No time time trackings found!",
  timeEntryExportPreviewEmptyResultContent:
    "Either you already exported the time time trackings or no time trackings for the selected export criteria are found.",
  timeEntryExportPreviewChooseCriteria:
    "Select the tenant and the period to see the time trackings included.",
  closeSnackbar: "Close",
  offlineBannerMessage: "You are offline.",
  openSidebar: "Open navigation",
  closeSidebar: "Close navigation",
  monitoringRunningTimeTrackingsHeadline: "Active time trackings",
  monitoringRunningTimeTrackingsActive: "active",
  monitoringRunningTimeTrackingsEmployeeCountTextSingular: "active employee",
  monitoringRunningTimeTrackingsEmployeeCountTextPlural: "active employees",
  monitoringRunningTimeTrackingsToolCountTextSingular: "active tool",
  monitoringRunningTimeTrackingsToolCountTextPlural: "active tools",
  monitoringNoRunningTimeTrackings: "Currently nobody is time tracking...",
  monitoringTimeEntriesTableHeadEmployee: "Employees / Tools",
  monitoringTimeEntriesTableHeadFrom: "since",
  monitoringTimeEntriesTableHeadWorkPackage:
    "Work package or construction site manager",
  monitoringConstructionSiteManagerAccountOf:
    "Construction site manager account of {name}",
  monitoringConstructionSiteManagerAccount: "Construction site manager account",
  monitoringTraining: "Training",
  teamPlanning: "Team planning",
  teamPlanningDescription: "Check on your teams' capacities.",
  teamPlanningCalendarWeekAbbr: "CW",
  teamPlanningTeamsTableHeader: "Teams",
  teamPlanningEmployeesTableHeader: "Employees",
  teamPlanningConstructionSitesTableHeader: "Construction sites",
  teamPlanningPreviousWeek: "previous week",
  teamPlanningNextWeek: "next week",
  teamPlanningPreviousMonth: "previous month",
  teamPlanningNextMonth: "next month",
  teamPlanningTeamlessEmployeesSectionHeader: "Employees without a team",
  teamPlanningTeamlessToolsSectionHeader: "Tools without a team",
  teamPlanningWeekDropdownCurrentWeekOption: "Current week",
  teamPlanningExpandAll: "Expand all",
  teamPlanningDateUnitSelectWeek: "Week",
  teamPlanningDateUnitSelectMonth: "Month",
  teamPlanningDateUnitSelectThreeMonths: "Next 12 weeks",
  teamPlanningGroupingSelectTeam: "Team",
  teamPlanningGroupingSelectEmployee: "Employee",
  teamPlanningGroupingSelectConstructionSite: "Construction site",
  teamPlanningPlanEntryTypeSelectAll: "All",
  teamPlanningPlanEntryTypeSelectWorkPackage: "Work packages",
  teamPlanningPlanEntryTypeSelectAbsence: "Absences",
  teamPlanningDrawerWorkPackageLabel: "Work package",
  teamPlanningDrawerDateRangeLabel: "Date range",
  teamPlanningDrawerManagerLabel: "Construction site manager",
  teamPlanningDrawerServiceCategoryLabel: "Service category",
  teamPlanningDrawerPlanningLink: "To planning",
  teamPlanningDrawerTimePeriodModalLink: "Postpone time period",
  teamPlanningTimePeriodModalTitle: "Postpone team planning",
  teamPlanningTimePeriodModalSubmitButton: "Postpone",
  teamPlanningTimePeriodModalByLabel: "by",
  teamPlanningTimePeriodModalToLabel: "to",
  teamPlanningTimePeriodModalUnitDay: "Days",
  teamPlanningTimePeriodModalUnitWeek: "Weeks",
  teamPlanningTimePeriodModalCountLabel: "Number",
  teamPlanningTimePeriodModalUnitLabel: "Days or weeks",
  teamPlanningTimePeriodModalDateLabel: "Date",
  appAdmin: "Debug & Config",
  appAdminSettings: "Config & Debug",
  appAdminStats: "Stats",
  settingsButton: "Settings",
  settingsCloseButton: "Close settings",
  settingsHeader: "Settings",
  settingsLanguageTitle: "Language",
  settingsBetaTitle: "Mode",
  settingsBetaDescription:
    "If you activate the BETA mode, you can test new features.",
  settingsBetaDisabled: "NORMAL",
  settingsBetaEnabled: "BETA",
  SettingsWereSaved: "Settings were saved",
  statistics: "Statistics",
  statisticsDescription: "Check your app's overall usage.",
  statisticsCurrentTenant: "Current tenant",
  statisticsAllTenants: "All tenants",
  statisticsHeaderStatistics: "Statistics",
  statisticsHeaderTotal: "Total",
  statisticsColumnTitleTeams: "Teams (avg. employee / team)",
  statisticsColumnTitleTeamsTooltip:
    "Determined with the date since the Grewe-Gruppe App first recorded the team.",
  statisticsColumnTitleEmployees: "Total employees",
  statisticsColumnTitleEmployeesTooltip:
    "Determined using the start or creation date",
  statisticsColumnTitleBlueCollarEmployees:
    "Blue collar employees (vs. total employees)",
  statisticsColumnTitleBlueCollarEmployeesTooltip:
    "Determined with the start or creation date and the role blue collar employee",
  statisticsColumnTitleConstructionSiteManagers:
    "Managers (vs. blue collar employees)",
  statisticsColumnTitleConstructionSiteManagersTooltip:
    "Determined with the start or creation date and the role of construction manager incl. Management",
  statisticsColumnTitleWorkPackages:
    "Work packages (avg. amount of work packages per construction site)",
  statisticsColumnTitleWorkPackagesTooltip:
    "Determined with the date since the work package was created in the Grewe-Group app. (work packages divided by all construction sites with at least one work package)",
  statisticsColumnTitleConstructionSites: "Active construction sites",
  statisticsColumnTitleConstructionSitesTooltip:
    "Determined with the date since the Grewe-Gruppe App first recorded/synchronized the construction site.",
  statisticsColumnTitleConstructionSitesWithWorkPackages:
    "Construction sites with work packages (vs. total construction sites)",
  statisticsColumnTitleAppendedEmployeeTimeEntries:
    "Manually added time entries (vs. total time entries)",
  statisticsColumnTitleAppendedEmployeeTimeEntriesTooltip:
    "All time entries with the property 'append'",
  statisticsColumnTitleModifiedEmployeeTimeEntries:
    "Modified time entries (vs. total time entries)",
  statisticsColumnTitleModifiedEmployeeTimeEntriesTooltip:
    "All time entries with the property 'modified'",
  statisticsColumnTitleApprovedUnexportedEmployeeTimeEntries:
    "Approved but not yet exported time entries",
  statisticsColumnTitleApprovedUnexportedEmployeeTimeEntriesTooltip:
    "All time entries with the property approved but not exported",
  statisticsColumnTitleExportedEmployeeTimeEntries: "Exported time entries",
  statisticsColumnTitleExportedEmployeeTimeEntriesTooltip:
    "All time entries with the property 'exported'",
  statisticsColumnTitleEmployeeTimeEntries: "Total time entries",
  statisticsColumnTitleEmployeeTimeEntriesTooltip: "All started time bookings",
  statisticsColumnTitleTimeEntriesTrackedByDistinctEmployees:
    "Number of entries tracked by distinct employees",
  statisticsColumnTitleTimeEntriesTrackedByDistinctEmployeesTooltip:
    "Number of time trackings started per employee summarized",
  statisticsColumnTitleTimeEntriesApprovedByDistinctEmployees:
    "Number of entries approved by distinct employees",
  statisticsColumnTitleTimeEntriesApprovedByDistinctEmployeesTooltip:
    "Number of approved time trackings per employee summarized",
  statisticsColumnTitleAverageDaysBetweenStartAndApproval:
    "Average number of days between time entry start and approval",
  statisticsColumnTitleAverageDaysBetweenStartAndApprovalTooltip:
    "The sum of all days divided by the number of all days from the start date to the approval date.",
  statisticsColumnTitleAverageDaysBetweenApprovalAndExport:
    "Average number of days between time entry approval and export",
  statisticsColumnTitleAverageDaysBetweenApprovalAndExportTooltip:
    "The sum of all days divided by the number of all days from the approval date to the export date.",
  statisticsColumnTitleAverageDaysBetweenStartAndExport:
    "Average number of days between time entry start and export",
  statisticsColumnTitleAverageDaysBetweenStartAndExportTooltip:
    "The sum of all days divided by the number of all days from the start date to the export date.",
  statisticsRowAddedEmployees: "Added employees",
  statisticsRowResignedEmployees: "Resigned employees",
  statisticsRowTotalEmployees: "Total employees",
  statisticsRowAddedConstructionSites: "New construction sites",
  statisticsRowDeletedConstructionSites: "Deleted construction sites",
  statisticsRowTotalConstructionSites: "Total construction sites",
  timeEntryHistoryModalTitle: "Change history",
  timeEntryHistoryModalTitleEntryFrom: "Time entry from",
  timeEntryHistoryModalChangeInfoStartAction: "Tracking started by",
  timeEntryHistoryModalChangeInfoAppendAction: "Tracking created manually by",
  timeEntryHistoryModalChangeInfoStopAction: "Tracking stopped by",
  timeEntryHistoryModalChangeInfoAutoStopAction:
    "Tracking stopped automatically by",
  timeEntryHistoryModalChangeInfoForceStopAction:
    "Tracking stopped manually by",
  timeEntryHistoryModalChangeInfoModifyAction: "Manual changes by",
  timeEntryHistoryModalChangeInfoApproveAction: "Approved by",
  timeEntryHistoryModalChangeInfoUnapproveAction: "Unapproved by",
  timeEntryHistoryModalChangeInfoExportAction: "Exported by",
  timeEntryHistoryModalChangeInfoUpdateCommuteTypeAction:
    "Commute info updated",
  timeEntryHistoryModalChangeInfoCommuteTimeAction: "Commute time calculated",
  timeEntryHistoryWorkPackageLabel: "Work package",
  timeEntryHistoryManagerLabel: "Manager",
  timeEntryHistoryAbsenceLabel: "Time entry account",
  myTimeEntries: "My time entries",
  myTimeEntriesDescription: "Check on your tracked time entries.",
  myTimeEntriesFromLabel: "from",
  myTimeEntriesToLabel: "to",
  myTimeEntriesArrivalCommuteLabel: "Arrival",
  myTimeEntriesDepartureCommuteLabel: "Departure",
  myTimeEntriesBreakLabel: "Break",
  myTimeEntriesNetLabel: "Net",
  myTimeEntriesWorkPackageLabel: "Work package",
  myTimeEntriesManagerLabel: "Manager",
  myTimeEntriesAbsenceLabel: "Time entry account",
  myTimeEntriesStatusLabel: "Status",
  myTimeEntriesEmptyResultTitle: "No time entries for this week",
  myTimeEntriesEmptyResultContent: "Please check the filter criteria",
  myTimeEntriesEmployeeSelectAllOption: "All employees",
  timeTrackingSynchronizedTitle: "Time tracking synchronized",
  timeTrackingOfflineTitle: "Time Tracking not synchronized",
  timeTrackingOfflineDescription:
    "Your work times have been saved and will be synced once you reconnect.",
  timeTrackingPositionErrorTitle: "Location Request Denied",
  timeTrackingPositionErrorContentParagraph1:
    "To start or stop the time tracking, geolocation tracking is required. Please allow gps positioning when asked.",
  timeTrackingPositionErrorContentParagraph2:
    "If the gps system is disabled, you can activate it in the system settings of your phone.",
  timeTrackingPositionUnavailableErrorContentParagraph1:
    "Unfortunately, position determination failed.",
  timeTrackingPositionTimeoutErrorContentParagraph1:
    "Position determination took too long.",
  timeTrackingPositionRetryableErrorContentParagraph2:
    "If you are inside a building, car or under a bridge, for example, please go outside and try again.",
  timeTrackingPositionUnsupportedErrorContentParagraph1:
    "Unfortunately, your browser does not support GPS tracking.",
  timeTrackingPositionUnsupportedErrorContentParagraph2:
    "Please update your browser or use another modern browser (e.g. chrome).",
  timeTrackingPositionUnknownErrorContentParagraph1:
    "An unknown error has occurred.",
  timeTrackingPositionErrorConfirm: "understood",
  taskBarAgeTooltip:
    "There are time entries that are older than {ageInDays} days.",
  searchFieldLabel: "Search",
  toolAdministrationCategoryFieldLabel: "Category",
  toolAdministrationBillabilityFieldLabel: "Tool type",
  toolAdministrationBillabilityOptionBillable: "Billable",
  toolAdministrationBillabilityOptionNonBillable: "Non-Billable",
  toolAdministrationStatusFieldLabel: "Status",
  toolAdministrationStatusOptionActive: "Active",
  toolAdministrationStatusOptionInactive: "Inactive",
  toolAdministrationEmptyResultsInfo:
    "No tools were found for the selected search criteria.",
  notificationsTitle: "Notifications",
  notificationsToday: "Today",
  notificationsMarkAsRead: "Mark all as read",
  notificationsActionResponsibilityTaken: "Taken",
  notificationsActionResponsibilityReturned: "Returned",
  notificationsActionToolDataChanged: "Data change",
  notificationsActionToolPermanentDecommissioned: "Decommissioned",
  notificationsActionToolPermanentRecommissioned: "Recommissioned",
  notificationsSender: "Changed by",
  notificationsTool: "Tool",
  notificationsOccurrence: "Occurrence",
  notificationsTime: "Time",
  notificationsDescription: "Description",
  notificationsDescriptionFromTo: 'changed from "{from}" to "{to}"',
  notificationsDescriptionTo: 'changed to "{to}"',
  notificationsDescriptionResponsibleEmployee: "Responsible employee",
  notificationsDescriptionToolReturned: "Returned by {by}",
  notificationsDescriptionManager: "Manager",
  notificationsDescriptionToolPermanentDecommissioned:
    'The tool was permanently decommissioned on {decommissionedAt} by "{employee}" with the reason "{decommissionReason}"',
  notificationsDescriptionToolPermanentRecommissioned:
    "The tool was recommissioned.",
  notificationsFilterUnread: "Unread",
  notificationsEmptyResult:
    "No notifications could be found for the selected search criteria.",
  organigram: "Organigram",
  organigramExportAsPdf: "Export organigram as PDF",
  organigramOrganizationTitle: "Organization",
  wageTypeChargableHours: "Chargable hours",
  wageTypeVacation: "Vacation",
  wageTypeVacationAbbreviation: "V",
  wageTypeIllness: "Illness",
  wageTypeIllnessAbbreviation: "I",
  wageTypeBankHolidays: "Bank Holidays",
  wageTypeBankHolidaysAbbreviation: "H",
  wageTypeCommuting: "Commuting",
  wageTypeCommutingAbbreviation: "C",
  wageTypeTraining: "Training",
  wageTypeTrainingAbbreviation: "T",
  wageTypePreparation: "Preparation",
  wageTypePreparationAbbreviation: "P",
  wageTypeAdministration: "Administration",
  wageTypeAdministrationAbbreviation: "A",
  wageTypeUnknown: "Unknown",
  wageTypeNumber: "Wage type number",
  resetAppCache: "Reload all app data",
  resetAppCacheSuccessToast: "All app data was reloaded",
  taskBarTitle: "My tasks",
  controllingKeyFigures: "Key Controlling Figures",
  controllingKeyFiguresDescription: "Check your key controlling figures.",
  controllingKeyFiguresBreadcrumbs: "Key Controlling Figures",
  controllingKeyFiguresUploadButton: "Upload file",
  controllingKeyFiguresBusinessLine: "Business Line",
  controllingKeyFiguresProfitContributionPerHour:
    "Profit Contribution per Hour",
  controllingKeyFiguresPlannedProfitContributionByBusinessLine:
    "Planned Profit Contribution",
  controllingKeyFiguresMonthlyHoursByBusinessLine: "Monthly hours",
  controllingKeyFiguresMonthlyProfitContributionByBusinessLine:
    "Monthly profit contribution",
  controllingKeyFiguresTotal: "Total",
  controllingKeyFiguresNothingToSeeTitle: "No data available",
  controllingKeyFiguresNothingToSeeContent: "Currently there is no data.",
  euroPerHour: "€ per hour",
  businessLine_administration: "Administration",
  businessLine_construction: "Construction",
  businessLine_maintenance: "Maintenance",
  businessLine_tree_care: "Tree care",
  businessLine_open_space_management: "Open Space Management",
  businessLine_unknown: "Unknown",
  monthlyTargetsUploadButton: "Upload file",
  monthlyTargetsUploadSuccess: "Data successfully saved.",
  monthlyTargetsUploadFailed: "Data could not be saved.",
  monthlyEmployeeTargetsButton: "Employee target hours",
  monthlyEmployeeTargetsUploadButton: "Upload",
  monthlyEmployeeTargetsUploadSuccess: "Data successfully saved.",
  monthlyEmployeeTargetsUploadFailed: "Data could not be saved.",
  monthlyEmployeeTargetsErrorRow: "Row %{rowNumber} has the following errors",
  monthlyEmployeeTargetsErrorInvalidRow:
    "Row already exists: Kürzel: %{tenant}, Ma-Nr: %{staffNumber}, Geschäftsfeld: %{businessLineCode}, Jahr: %{year}",
  monthlyEmployeeTargetsErrorBusinessLineUnknown:
    "Unknown business line: %{businessLineCode}",
  monthlyEmployeeTargetsMoreErrors: "More rows have errors...",
  monthlyEmployeeTargetsError_tenant_required: "Abbreviation missing",
  monthlyEmployeeTargetsError_staff_number_required: "Staff number missing",
  monthlyEmployeeTargetsError_business_line_code_required:
    "Business line code missing",
  monthlyEmployeeTargetsError_year_required: "Year missing",
  monthlyEmployeeTargetsError_tenant_unknown: "Tenant unknown",
  unknownBackendError: "Invalid",
  manageWageTypes: "Wage types",
  manageWageTypesDescription: "See how wage types are used in the app.",
  manageWageTypesTableHeaderName: "Name",
  manageWageTypesTableHeaderNumber: "Number",
  manageWageTypesTableHeaderImportInterpretation: "Import interpretation",
  manageWageTypesTableHeaderExportInterpretation: "Export interpretation",
  manageWageTypesTableHeaderCount: "# Booking Entries",
  navigationGroup_time: "Time Management",
  navigationGroup_resources: "Resources",
  navigationGroup_planning: "Planning",
  navigationGroup_controlling: "Controlling",
  navigationGroup_health: "Health-Board",
  dashboardGreeting: "Hi",
  exportButtonLabel: "Export",
  exportButtonErrorMessage:
    "Something went wrong while exporting. Please try again later.",
  resourceTypeSelectLabel: "Employees/Tools",
  resourceTypeSelectOptionAll: "All",
  resourceTypeSelectOptionEmployee: "Only employees",
  resourceTypeSelectOptionTool: "Only tools",
  teamDeletedTooltip: "Team was deleted",
  azureTeamSyncButton: "Sync with MS Teams",
  azureTeamSyncButtonRetry: "Retry MS Teams Sync",
  azureTeamSyncStatusSyncing: "MS Teams Sync in Progress",
  azureTeamSyncStatusSynced: "Synchronized with MS Teams",
  azureTeamSyncModalTitle: "MS Teams Synchronisation",
  azureTeamSyncModalInfoText:
    "Synchronizing with Microsoft Teams can take up to <strong>2 days</strong>.",
  azureTeamSyncModalCreateLabel: "Create and connect new Team",
  azureTeamSyncModalConnectLabel: "Connect with an existing Team",
  azureTeamSyncModalConnectInputLabel: "Enter teams link",
  azureTeamSyncModalConnectInputErrorInvalidLink:
    "Please enter a valid teams link",
  azureTeamSyncModalConnectInputErrorTeamNotFound:
    "No team found for this link.",
  azureTeamSyncModalConnectInputErrorTeamInUse:
    "This team is already connected to another construction site.",
  azureTeamSyncModalNamePreview:
    'The new group will be found in Teams under "{teamName}".',
  azureTeamSyncModalAddEmployeesLabel: "Add additional employees",
  azureTeamSyncModalSubmitButton: "Synchronize",
  azureTeamSyncErrorText:
    "Synchronization with Teams has failed. Feel free to try again, your system administrators have already been informed about the error.",
  emptySearchResult:
    "No entries could be found for the selected search criteria.",
  workingTimeMissing:
    "The employee was not yet employed on the specified date.",
  quickCheckInCheckOutDialogHeaderArrival: "Edit arrival commute",
  quickCheckInCheckOutDialogHeaderDeparture: "Edit departure commute",
  quickCheckInCheckOutDialogResourceTitleArrival:
    "Arrival commute of {resourceName}",
  quickCheckInCheckOutDialogResourceTitleDeparture:
    "Departure commute of {resourceName}",
  quickCheckInCheckOutDialogResourceSubtitleArrival:
    "Where did {resourceName} come from?",
  quickCheckInCheckOutDialogResourceSubtitleDeparture:
    "Where is {resourceName} going now?",
  quickCheckInCheckOutDialogDriverSiteArrivalButtonLable:
    "<b>From the depot</b> as a <b>driver</b>",
  quickCheckInCheckOutDialogDriverSiteDepartureButtonLable:
    "<b>To the depot</b> as a <b>driver</b>",
  quickCheckInCheckOutDialogPassengerSiteArrivalButtonLable:
    "<b>From the depot</b> as a <b>passenger</b>",
  quickCheckInCheckOutDialogPassengerSiteDepartureButtonLable:
    "<b>To the depot</b> as a <b>passenger</b>",
  quickCheckInCheckOutDialogDriverHomeArrivalButtonLable:
    "<b>From home</b> as a <b>driver/in</b>",
  quickCheckInCheckOutDialogDriverHomeDepartureButtonLable:
    "<b>To home</b> as a <b>driver/in</b>",
  quickCheckInCheckOutDialogPassengerHomeArrivalButtonLable:
    " <b>From home</b> as a <b>passenger/in</b>",
  quickCheckInCheckOutDialogPassengerHomeDepartureButtonLable:
    "<b>To home</b> as a <b>passenger/in</b>",
  quickCheckInCheckOutDialogNoCommuteButtonLable: "No commuting expenses",
} as const;
