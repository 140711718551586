import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext";
import { config } from "~initializer/config";

export function useCommuteExpensesFeatureEnabled() {
  const { number } = useCurrentTenant();

  const configValue = config().commuteExpensesFeatureEnabled;
  if (!configValue) {
    return false;
  }

  return configValue.split(",").includes(`${number}`);
}
