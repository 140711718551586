import { createContext, useCallback, useContext, useState } from "react";

import { PositionErrorDialog } from "~contexts/PositionContext/PositionErrorDialog";

import type { PropsWithChildren } from "react";

const MAXIMUM_AGE_IN_MS = 10000;
const TIMEOUT_IN_MS = 10000;

export const GEOLOCATION_POSITION_UNSUPPORTED = -1;

type RequestPositionArgs = {
  maximumAge?: number;
  enableHighAccuracy?: boolean;
};

interface PositionContextType {
  requestPosition: (args?: RequestPositionArgs) => Promise<GeolocationPosition>;
  loading: boolean;
}
export const PositionContext = createContext<PositionContextType | null>(null);

export function PositionProvider({
  children,
  renderErrorDialog = true,
}: PropsWithChildren<{ renderErrorDialog?: boolean }>) {
  const [loading, setLoading] = useState(false);
  const [positionError, setPositionError] =
    useState<GeolocationPositionError>();
  const resetPositionError = () => setPositionError(undefined);

  const requestPosition = useCallback(
    ({
      maximumAge = MAXIMUM_AGE_IN_MS,
      enableHighAccuracy = false,
    }: RequestPositionArgs = {}) => {
      setLoading(true);
      const geo = navigator.geolocation;

      return new Promise<GeolocationPosition>((resolve, reject) => {
        if (!geo) {
          const unsupportedError: GeolocationPositionError = {
            code: GEOLOCATION_POSITION_UNSUPPORTED,
            message: "Geolocation not available",
            PERMISSION_DENIED: 1,
            POSITION_UNAVAILABLE: 2,
            TIMEOUT: 3,
          };
          setLoading(false);
          setPositionError(unsupportedError);
          reject(positionError);
          return;
        }

        geo.getCurrentPosition(
          (pos: GeolocationPosition) => {
            setLoading(false);
            resetPositionError();
            resolve(pos);
          },
          (err: GeolocationPositionError) => {
            setLoading(false);
            setPositionError(err);
            reject(err);
          },
          {
            maximumAge,
            timeout: TIMEOUT_IN_MS,
            enableHighAccuracy,
          },
        );
      });
    },
    [positionError],
  );

  return (
    <PositionContext.Provider value={{ requestPosition, loading }}>
      {children}
      {renderErrorDialog && positionError && (
        <PositionErrorDialog
          error={positionError}
          onConfirm={resetPositionError}
        />
      )}
    </PositionContext.Provider>
  );
}

export function usePosition(): PositionContextType {
  const context = useContext(PositionContext);
  if (!context) {
    throw new Error("usePosition must be used within a PositionProvider");
  }

  return context;
}
