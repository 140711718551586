import { SharedAbsence } from "~generated";

import type { TranslationKey } from "~contexts/I18nContext/I18nContext.tsx";

export const absenceCategoryTranslations: Record<
  SharedAbsence.category,
  TranslationKey
> = {
  [SharedAbsence.category.SCHOOL]: "absenceCategorySchool",
  [SharedAbsence.category.SICKNESS]: "absenceCategorySickness",
  [SharedAbsence.category.TRAINING]: "absenceCategoryTraining",
  [SharedAbsence.category.VACATION]: "absenceCategoryVacation",
  [SharedAbsence.category.LEISURE_TIME_ACCOUNT]:
    "absenceCategoryLeisureTimeAccount",
};
