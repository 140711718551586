import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import { Dialog } from "~components/shared/Dialog/Dialog";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { GEOLOCATION_POSITION_UNSUPPORTED } from "~contexts/PositionContext/PositionContext";

export interface PositionErrorDialogProps {
  error: GeolocationPositionError;
  onConfirm: () => void;
}

export function PositionErrorDialog({
  error,
  onConfirm,
}: PositionErrorDialogProps) {
  const t = useTranslation();

  return (
    <Dialog
      icon={<DoDisturbIcon />}
      open={true}
      dialogTitle={t.timeTrackingPositionErrorTitle}
    >
      <DialogContent>
        <Error error={error} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm}>Verstanden</Button>
      </DialogActions>
    </Dialog>
  );
}

type ErrorProps = {
  error: GeolocationPositionError;
};
function Error({ error }: ErrorProps) {
  const t = useTranslation();

  switch (error.code) {
    case error.PERMISSION_DENIED:
      return (
        <>
          <p>{t.timeTrackingPositionErrorContentParagraph1}</p>
          <p>{t.timeTrackingPositionErrorContentParagraph2}</p>
        </>
      );
    case error.POSITION_UNAVAILABLE:
      return (
        <>
          <p>{t.timeTrackingPositionUnavailableErrorContentParagraph1}</p>
          <p>{t.timeTrackingPositionRetryableErrorContentParagraph2}</p>
        </>
      );
    case error.TIMEOUT:
      return (
        <>
          <p>{t.timeTrackingPositionTimeoutErrorContentParagraph1}</p>
          <p>{t.timeTrackingPositionRetryableErrorContentParagraph2}</p>
        </>
      );
    case GEOLOCATION_POSITION_UNSUPPORTED:
      return (
        <>
          <p>{t.timeTrackingPositionUnsupportedErrorContentParagraph1}</p>
          <p>{t.timeTrackingPositionUnsupportedErrorContentParagraph2}</p>
        </>
      );
    default:
      return (
        <>
          <p>{t.timeTrackingPositionUnknownErrorContentParagraph1}</p>
          <p>{t.timeTrackingPositionRetryableErrorContentParagraph2}</p>
        </>
      );
  }
}
