/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrowserGeolocationPosition } from './BrowserGeolocationPosition';
import type { CommuteType } from './CommuteType';
import type { Latitude } from './Latitude';
import type { Longitude } from './Longitude';
import type { Resource } from './Resource';
export type TimeTrackingStopEventBody = {
    action: TimeTrackingStopEventBody.action;
    stop_time: string;
    pause_duration_seconds: number;
    latitude?: Latitude;
    longitude?: Longitude;
    accuracy?: (number | null);
    work_package_id?: string;
    reference: string;
    resource: Resource;
    departure_commute_type: CommuteType;
    position?: (BrowserGeolocationPosition | null);
};
export namespace TimeTrackingStopEventBody {
    export enum action {
        STOP = 'stop',
    }
}

