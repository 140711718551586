import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

import type { TranslationKey } from "~contexts/I18nContext/I18nContext.tsx";
import type { ToolDecommissionReason } from "~generated";

const DECOMMISSION_REASON_TRANSLATIONS: Record<
  ToolDecommissionReason,
  TranslationKey
> = {
  unknown: "toolDecommissionReasonUnknown",
  scrap: "toolDecommissionReasonScrap",
  inventory_shrinkage: "toolDecommissionReasonInventoryShrinkage",
  sale: "toolDecommissionReasonSale",
  theft: "toolDecommissionReasonTheft",
  return_after_leasing: "toolDecommissionReasonReturnAfterLeasing",
  long_term_rental_ended: "toolDecommissionReasonLongTermRentalEnded",
  error: "toolDecommissionReasonError",
};

export function useToolDecommissionReasons() {
  const t = useTranslation();

  const getDecommissionReasonLabel = (reason: ToolDecommissionReason) =>
    t[DECOMMISSION_REASON_TRANSLATIONS[reason]];

  return {
    getDecommissionReasonLabel,
  };
}
