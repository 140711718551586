import CloseIcon from "@mui/icons-material/Close";
import MuiDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import React from "react";

import { IconWithLabel } from "~components/shared/Icon/IconWithLabel.tsx";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { classList } from "~lib/classList.ts";

import type { DialogProps as MuiDialogProps } from "@mui/material/Dialog";
import type { TransitionProps } from "react-transition-group/Transition";

import styles from "./Dialog.module.scss";

export interface DialogProps extends MuiDialogProps {
  dialogTitle?: React.ReactNode;
  icon?: React.ReactNode;
  isCancelable?: boolean;
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) => <Slide direction="up" ref={ref} {...props} />,
);
Transition.displayName = "Transition";

export function Dialog({
  children,
  dialogTitle,
  icon,
  TransitionComponent,
  onClose,
  isCancelable = true,
  ...props
}: DialogProps) {
  const t = useTranslation();

  const handleClose: DialogProps["onClose"] = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }

    if (onClose) {
      onClose(event, reason);
    }
  };

  return (
    <MuiDialog
      TransitionComponent={TransitionComponent || Transition}
      onClose={handleClose}
      PaperComponent={Paper}
      className={styles.container}
      {...props}
    >
      <DialogTitle className={classList(styles.title)}>
        <IconWithLabel icon={icon}>{dialogTitle}</IconWithLabel>
        {isCancelable && onClose && (
          <IconButton
            size="small"
            aria-label={t.close}
            color="inherit"
            onClick={(event) => onClose(event, "escapeKeyDown")}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      {children}
    </MuiDialog>
  );
}
